import React from 'react';
import classNames from 'classnames';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { FormattedMessage, injectIntl, intlShape, useIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';

import {
  Button,
  Form,
  FieldTextInput,
  FieldSelect,
  FieldRadioButton,
} from '..';
import { createGoogleMapsLinkWithLatLng } from '../../util/urlHelpers';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import { formatMoney } from '../../util/currency';

import css from './EditPendingSessionForm.module.css';
import moment from 'moment';

const EditPendingSessionFormComponent = props => {
  const { onSubmit, exceptionUpdateRequest, marketplaceCurrency, ...restOfProps } = props;
  const intl = useIntl();
  const required = validators.required(intl.formatMessage({ id: "EditPendingSessionForm.fieldRequired" }));

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={(values, form) => onSubmit({ ...values }, form)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          children,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          classes,
          autoFocus,
          ready,
          form,
          formId,
          values,
          updateParentTransactionRequest
        } = fieldRenderProps;

        const submitDisabled = !values;
        const { title, publicData } = values?.listingAttributes || {};
        const { dayAvailability, city: locationName } = publicData || {};
        const { unixDate: startDate, isGuest, guestFirstName, guestLastName, guestPhoneNumber, additionalLinks,date } = values?.attributes || {};
        
        const dayOfWeek = moment(date,"DD-MM-YYYY").format('ddd').toLowerCase();
        const instructors = dayAvailability && dayAvailability.filter(e => e.dayOfWeek == dayOfWeek).map(e => e.instructors).flat();
        const { additionalEquipments } = values.userPackages?.attributes?.additionalInformation || {};
        return (
          <Form className={classes} onSubmit={(values) => handleSubmit(values, form)}>
            <div className={css.joinForm}>
              <h3> {intl.formatMessage({ id: "EditPendingSessionForm.informationAboutLabel" })} {title}</h3>
              {/* {description ?
                            <div className={css.descriptionBox}>
                                <div className={css.descriptionHeading}>Description</div>
                                <div className={css.descriptionTitle} dangerouslySetInnerHTML={{ __html: description.replace(/＜/g, '<').replace(/＞/g, '>') }} />
                            </div>
                            : null} */}
              {locationName ?
                <div className={css.addressBox}>
                  {/* <div className={css.addressName}>
                                        <span className={css.addressHeading}>{intl.formatMessage({ id: "EditPendingSessionForm.locationAddressLabel" })}</span>
                                        <input type='text' value={locationName} />
                                    </div> */}
                </div>
                : null}

              {instructors && instructors.length && instructors.filter((st) => st && st.value).length ?
                <div className={css.tutorBox}>
                  <span className={css.tutorHeading}>{intl.formatMessage({ id: "EditPendingSessionForm.tutors" })} </span>
                  <input type='text' value={instructors.filter((st) => st && st.value).map((st) => st.value).join(",")}></input>
                </div>
                : null}


              {additionalLinks && additionalLinks.length && additionalLinks.filter((st) => st.locationName).length ? <div>
                {/* <label><FormattedMessage id="AdditionalQuestionForm.pickupAddress" /></label> */}
                <FieldSelect
                  name={`userPickupPoint`}
                  id={`userPickupPoint`}
                  className={css.inputBox}
                  label={intl.formatMessage({ id: 'AdditionalQuestionForm.userPickupPoint' })}
                  // validate={requiredNonEmptyString}
                  onChange={(e) => {
                    form.change("userPickupPoint", e);
                    if (e) {
                      const filterdValue = additionalLinks.find((st) => st.locationName == e);
                      form.change("userPickupPointTime", filterdValue.pickupTime);
                      form.change("userPickupPointAddress", filterdValue.location);
                      form.change("userPickupPointURL", filterdValue.locationURL);
                    }
                  }}
                >
                  <option value="" selected>
                    {intl.formatMessage({ id: 'AdditionalQuestionForm.selectPickupPoint' })}
                  </option>
                  {additionalLinks.filter((st) => st.locationName).map((st, i) => <option key={st.locationName + i} value={st.locationName}>{st.locationName}</option>)}
                </FieldSelect>
                {values.userPickupPoint ? <>
                  {values.userPickupPointAddress ? <div className={css.pickupPointBox}><span><FormattedMessage id="AdditionalQuestionForm.firstPickupPoint" /></span><input type='text' value={values.userPickupPointAddress.search} /></div> : null}
                  {values.userPickupPointTime ? <div className={css.pickupTimeBox}><span><FormattedMessage id="AdditionalQuestionForm.secondPickupTime" /></span> <input type='text' value={values.userPickupPointTime} /> </div> : null}
                </> : null}
              </div> : null}

              {/* {(values.isShareable && values.isShareable == "yes")
                                ? */}

              {/* <div className={css.sharebleBox}>
                                <label> {intl.formatMessage({ id: "EditPendingSessionForm.whoIsJoiningSessionLabel" })}</label>
                                <div className={css.radioButtonRow}>
                                    <FieldRadioButton
                                        id="you"
                                        name="isGuest"
                                        label={intl.formatMessage({ id: "EditPendingSessionForm.youLabel" })}
                                        value="you"
                                        onChange={(e) => {
                                            form.change("isGuest", "you");
                                            form.change("guestFirstName", "");
                                            form.change("guestLastName", "");
                                            form.change("guestPhoneNumber", "");
                                        }}
                                        showAsRequired={required}
                                    />
                                     <FieldRadioButton
                                        id="guest"
                                        name="isGuest"
                                        label={intl.formatMessage({ id: "EditPendingSessionForm.guestLabel" })}
                                        value="guest"
                                        showAsRequired={required}
                                    />
                                </div>

                                {values.isGuest && values.isGuest == "guest" ?
                                    <>
                                        <div className={css.formRow}>
                                            <FieldTextInput
                                                className={css.inputBox}
                                                type={"text"}
                                                label={intl.formatMessage({ id: "EditPendingSessionForm.firstNameLabel" })}
                                                name={`guestFirstName`}
                                                id={`guestFirstName`}
                                                validate={required}
                                            />
                                            <FieldTextInput
                                                className={css.inputBox}
                                                type={"text"}
                                                label={intl.formatMessage({ id: "EditPendingSessionForm.lastNameLabel" })}
                                                name={`guestLastName`}
                                                id={`guestLastName`}
                                                validate={required}
                                            />
                                        </div>
                                        <FieldTextInput
                                            className={css.inputBox}
                                            type={"text"}
                                            label={intl.formatMessage({ id: "EditPendingSessionForm.mobileNumberLabel" })}
                                            name={`guestPhoneNumber`}
                                            id={`guestPhoneNumber`}
                                            validate={required}
                                        />
                                    </> : null}
                            </div> */}

              {/* : null} */}

              {values && values.additionalQuestions && values.additionalQuestions.length ? <>
                <h4>{intl.formatMessage({ id: "EditPendingSessionForm.additionalQuestionsLabel" })}</h4>
                <FieldArray
                  name={`additionalQuestions`}
                  render={({ fields }) => (
                    <React.Fragment>
                      {fields.map((name, index) => {
                        if (values.additionalQuestions[index].questionType == "boolean") {
                          return <FieldSelect
                            name={`${name}.value`}
                            id={`${name}.value`}
                            className={css.inputBox}
                            label={values.additionalQuestions[index].question}
                            validate={required}
                          >
                            <option value="" selected>
                              {intl.formatMessage({ id: "EditPendingSessionForm.selectLabel" })}
                            </option>
                            <option value={"yes"}>{intl.formatMessage({ id: "EditPendingSessionForm.yesLabel" })}</option>
                            <option value={"no"}>{intl.formatMessage({ id: "EditPendingSessionForm.noLabel" })}</option>
                          </FieldSelect>
                        } else if (values.additionalQuestions[index].questionType == "select" && values.additionalQuestions[index].questionOption && values.additionalQuestions[index].questionOption.length) {
                          return <FieldSelect
                            name={`${name}.value`}
                            id={`${name}.value`}
                            className={css.inputBox}
                            label={values.additionalQuestions[index].question}
                            validate={required}
                          >
                            <option value="" selected>
                              {intl.formatMessage({ id: "EditPendingSessionForm.selectLabel" })}
                            </option>
                            {values.additionalQuestions[index].questionOption.map((itt) => <option value={itt}>{itt}</option>)}

                          </FieldSelect>
                        }
                        return (<div key={"additionalQuestions" + index}>
                          <FieldTextInput
                            className={css.inputBox}
                            type={values.additionalQuestions[index].questionType}
                            label={values.additionalQuestions[index].question}
                            validate={required}
                            name={`${name}.value`}
                            id={`${name}.value`}
                          />
                        </div>
                        )
                      })}
                    </React.Fragment>
                  )}
                />
              </> : null}

              <FieldArray
                name="additionalEquipmentCost"
                render={({ fields }) => (
                  <div className={css.extraInput}>
                    {fields.map((additionalEquipmentName, extraFieldIndex) => {
                      const {
                        id,
                        label,
                        price,
                        question,
                        options
                      } = Array.isArray(values.additionalEquipmentCost) && values.additionalEquipmentCost.length && typeof values.additionalEquipmentCost[extraFieldIndex] == "object" ? values.additionalEquipmentCost[extraFieldIndex] : {};

                      return (<div key={additionalEquipmentName}>
                        {/* Title Field */}
                        <FieldSelect
                          id={`${additionalEquipmentName}.id`}
                          className={css.inputBox}
                          name={`${additionalEquipmentName}.id`}
                          label={question}
                          validate={required(intl.formatMessage({ id: 'ProductOrderForm.fieldRequired' }))}
                        >
                          <option value="" disabled>
                            {intl.formatMessage({ id: 'ProductOrderForm.optionSelect' })}
                          </option>
                          {options.filter((st) => st.price <= price).map((item, i) => {
                            const newlabel = item.label;
                            const formattedprice = new Money(item.price || 0, marketplaceCurrency || "EUR")
                            return (
                              <option key={item.id} value={item.id}>
                                {newlabel} - {formatMoney(intl, formattedprice)}
                              </option>)
                          })}
                        </FieldSelect>
                      </div>
                      )
                    }
                    )}
                  </div>
                )}
              />
            </div>

            <div className={css.bottomButton}>
              <Button
                type="submit"
                inProgress={updateParentTransactionRequest}
                disabled={submitDisabled}
                ready={ready}
              >
                {intl.formatMessage({ id: "EditPendingSessionForm.updateLabel" })}
              </Button>
            </div>
          </Form>
        )
      }}
    />
  );
};

EditPendingSessionFormComponent.defaultProps = {
  className: null,
  disabled: false,
  inProgress: false,
  ready: false,
  fieldRenderProps: null,
};

EditPendingSessionFormComponent.propTypes = {
  className: string,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),
  // from injectIntl
  intl: intlShape.isRequired,
};

const EditPendingSessionForm = compose(injectIntl)(EditPendingSessionFormComponent);

export default EditPendingSessionForm;
